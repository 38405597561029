<template>
  <div class="login-container"><div class="blur-mask"></div></div>
  <div class="login-box">
    <div class="logo">
      <img src="~@/assets/branc-banner.png" data-v-583659c0="" style="height: 56px;visibility:hidden" />
    </div>
    <div class="head">
      <p>陆家嘴重大项目招商系统</p>
    </div>
    <!--登陆-->
    <form onsubmit="return false">
      <div class="item">
        <input type="text" name="name" maxlength="11" placeholder="请输入您的帐号" autocomplete="off" class="name" v-model="authInfo.username" />
      </div>
      <!-- <div class="item">
        <input
          type="text"
          name="vcode"
          maxlength="6"
          placeholder="请输入验证码"
          autocomplete="off"
          class="vcode"
        />
        <span id="vcode">发送验证码</span>
      </div> -->
      <div class="item">
        <input type="password" name="password" placeholder="请再次输入您的密码" autocomplete="off" class="password" v-model="authInfo.password" />
      </div>
      <!-- <p>忘记密码</p> -->
      <div class="submit">
        <button @click="login">登&nbsp;陆</button>
      </div>
      <va-alert icon="error" border-color="#FF0000" color="#FF0000" v-if="invalidMsg != ''">
        {{ invalidMsg }}
      </va-alert>
    </form>

    <!--<div class="other-login">
    <p><span>使用第三方帐号登陆</span></p>
    <div class="third">
      <ul>
        <li><img src="./移动端登录页面模板_files/icon-qq.png"></li>
        <li><img src="./移动端登录页面模板_files/icon-weixin.png"></li>
        <li><img src="./移动端登录页面模板_files/icon-weibo.png"></li>
      </ul>
    </div>
  </div>-->
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axiosService from '@/services/AxiosService'

export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const authInfo = reactive({
      username: '',
      password: ''
    })
    const invalidMsg = ref('')

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

    function login() {
      if (authInfo.username === '' || authInfo.password === '') {
        invalidMsg.value = '请填写用户名密码！'
        return false
      }

      axiosService.post('api/user/session', {
          userName: authInfo.username,
          password: authInfo.password
        })
        .then(res => {
          toast({ message: '登录成功！', color: 'success', closeable: false, duration: 2000 })
          setTimeout(() => {
            window.localStorage.setItem('user_info', JSON.stringify(res.data))
            store.dispatch('setUserInfo', res.data)
            router.push({ path: '/corporate' })
          }, 500)
        }).catch(error => {
          if (error.response && error.response.status && error.response.status === 401) {
            if (error.response.data.incorrectPassword) {
              invalidMsg.value = '密码不正确！'
            }

            if (error.response.data.incorrectUserName) {
              invalidMsg.value = '该用户不存在！'
            }

            if (error.response.data.invalidSmscode) {
              invalidMsg.value = '短信验证码验证失败！'
            }
          }
        })
      return false
    }

    return {
      authInfo,
      invalidMsg: ref(invalidMsg),
      login
    }
  }
})
</script>

<style lang="scss" scoped>
.login-container {
  display: flex;
  justify-content: center;
  @media (min-width: 992px) {
    background: url(~@/assets/account/lujiazui_big_bg1.png) center;
    background-size: cover;
    height: 100vh;
    .blur-mask {
      width: 100%;
      height: 100%;
      backdrop-filter: blur(2px);
    }
  }
}
.login-box {
  width: 100%;
  overflow-x: hidden;
  background: url(~@/assets/account/top_bg.png) top left no-repeat;
  background-color: #fff;
  background-size: 100%;
  @media (min-width: 992px) {
    position: fixed;
    top: 12rem;
    width: 30rem;
    height: 32rem;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 0.5rem;
  }
  img {
    vertical-align: middle;
    max-width: 100%;
  }
  .logo {
    padding: 0.8rem 0 0 1rem;
    img{
      height: 66px;
    } 
  }

  a:hover,
  a:active,
  a:visited,
  a:link,
  a:focus {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    text-decoration: none;
  }
  /*头像*/
  .head {
    height: 12rem;
    padding-top: 1.5rem;
    p {
      text-align: center;
      color: #e1e0e3;
      font-size: 1.675rem;
      padding-left: 1rem;
    }
  }
  /*登陆表单*/
  form {
    width: 100%;
    height: auto;
    overflow: hidden;
    padding: 2.775rem 0.675rem 0.675rem 0.675rem;
    .item {
      display: flex;
      align-items: center;
      height: 2.5rem;
      margin-bottom: 0.525rem;
      font-size: 0.45rem;
      color: #333333;
      input {
        width: 100%;
        height: 100%;
        display: inline-block;
        border: 0.015rem solid #f5e5e5;
        border-radius: 1.275rem;
        box-shadow: 0px 0px 10px rgba(200, 200, 200, 0.1);
        padding-left: 2.2rem;
        font-size: 0.8rem;
        outline: none;
        &.name {
          background: url('~@/assets/account/icon-phone.png') left 1rem center no-repeat;
          background-size: 0.7rem 1rem;
        }
        &.vcode {
          background: url(~@/assets/account/icon-verify.png) left 1rem center no-repeat;
          background-size: 0.7rem 0.9rem;
        }
        &.password {
          background: url('~@/assets/account/icon-lock.png') left 1rem center no-repeat;
          background-size: 0.7rem 0.9rem;
        }
      }
      span {
        flex-shrink: 0;
        display: inline-block;
        width: 4.5rem;
        line-height: 2.5rem;
        margin-left: 0.3rem;
        height: 100%;
        border-radius: 1.275rem;
        background: #1f6ecf;
        font-size: 0.7rem;
        color: #fff;
        text-align: center;
        &.disabled {
          background: #808080;
        }
      }
    }
    p {
      text-align: center;
      color: #333333;
      font-size: 0.8rem;
      padding: 0.5rem 0;
    }
    .submit {
      padding: 0.3rem 0.3rem;
      button {
        height: 2.8rem;
        line-height: 1.275rem;
        text-align: center;
        font-size: 1rem;
        color: #ffffff;
        border-radius: 1.275rem;
        outline: none;
        box-shadow: 0 0.225rem 0.225rem rgba(31, 110, 207, 0.2);
        display: inline-block;
        width: 100%;
        background: #1f6ecf;
      }
    }
  }

  /*第三方登陆*/
  .other-login p {
    text-align: center;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5.55rem;
    margin: 0 auto;
  }
  .other-login p:after {
    content: '';
    height: 0.015rem;
    width: 100%;
    background: #808080;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
  }
  .other-login p span {
    display: inline-block;
    padding: 0.015rem 0.45rem;
    background: #ffffff;
    color: #808080;
    font-size: 0.33rem;
    z-index: 2;
  }
  .other-login .third ul {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .other-login .third ul li img {
    width: 0.99rem;
    height: 0.99rem;
    margin: 0 0.375rem;
  }
  /*尾部*/
  .footer {
    text-align: center;
    padding-top: 0.45rem;
  }
  .footer img {
    display: inline-block;
    width: 1.605rem;
    height: 0.525rem;
  }
  .footer p {
    font-size: 0.3rem;
    color: #666666;
    text-align: center;
    padding: 0.15rem 0 0.6rem 0;
    letter-spacing: 0.015rem;
  }
  .footer p a {
    color: #078dda;
  }
}
</style>
