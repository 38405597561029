
import { defineComponent, reactive, ref, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import axiosService from '@/services/AxiosService'

export default defineComponent({
  name: 'Login',
  setup() {
    const store = useStore()
    const router = useRouter()
    const app = getCurrentInstance()
    const authInfo = reactive({
      username: '',
      password: ''
    })
    const invalidMsg = ref('')

    function toast(config: any) {
      if (app) {
        const vaToast = app.appContext.config.globalProperties.$vaToast
        vaToast.init(config)
      }
    }

    function login() {
      if (authInfo.username === '' || authInfo.password === '') {
        invalidMsg.value = '请填写用户名密码！'
        return false
      }

      axiosService.post('api/user/session', {
          userName: authInfo.username,
          password: authInfo.password
        })
        .then(res => {
          toast({ message: '登录成功！', color: 'success', closeable: false, duration: 2000 })
          setTimeout(() => {
            window.localStorage.setItem('user_info', JSON.stringify(res.data))
            store.dispatch('setUserInfo', res.data)
            router.push({ path: '/corporate' })
          }, 500)
        }).catch(error => {
          if (error.response && error.response.status && error.response.status === 401) {
            if (error.response.data.incorrectPassword) {
              invalidMsg.value = '密码不正确！'
            }

            if (error.response.data.incorrectUserName) {
              invalidMsg.value = '该用户不存在！'
            }

            if (error.response.data.invalidSmscode) {
              invalidMsg.value = '短信验证码验证失败！'
            }
          }
        })
      return false
    }

    return {
      authInfo,
      invalidMsg: ref(invalidMsg),
      login
    }
  }
})
